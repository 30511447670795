<template>
	<a @click.prevent="visitBookingEngine({ ...props })">
		<slot />
	</a>
</template>

<script setup>
import { BookingEngineFactory } from '@becurious/mews';

const { locale } = useI18n();
const bookingEngine = useState('bookingEngine', () => {});

const props = defineProps({
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	promo: { type: String, default: '' },
	roomID: { type: String, default: '' },
	language: { type: String, default: '' },
});

onMounted(() => {
	if (!bookingEngine.value) {
		const factory = new BookingEngineFactory({
			hotelID: '8f9841fa-4733-4243-9e58-af9000e80372',
			primaryColor: '#253530',
			mode: 'widget',
		});

		bookingEngine.value = factory.createBookingEngine();
	}
});

const visitBookingEngine = (options) => {
	if (!bookingEngine.value) {
		return null;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	return bookingEngine.value.visitUrl(options);
};

useHead({
	script: [
		{
			hid: 'booking-engine',
			src: 'https://api.mews.com/distributor/distributor.min.js',
		},
	],
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
